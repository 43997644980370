import 'normalize.css';
import { Provider } from 'react-redux';
import Pages from './pages';
import { store } from './store';

function App() {
  	return (
		<div className="App">
			<Provider store={store}>
				<Pages/>
			</Provider>
		</div>
	);
}

export default App;
