


import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { motion, useAnimationFrame, useMotionValue } from "framer-motion";
import _ from "lodash";

export function importAll(r) {
    // let images = {};
    let images = [];
    r.keys().forEach((item, index) => {
    //   images[item.replace('./', '')] = r(item);
        images.push(r(item));
    });
    return images;
}


const Client = ()=>{
    const [elmts, setElmts] = useState([]);
    const started = useRef(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState([[]]);
    const ref = useRef();
    const elements = useRef(
        importAll(require.context('../assets/clients', false, /\.(png|jpe?g|svg)$/))
    ).current 

    const handlePage = useCallback((type)=>{

        if (type === "next"){
            if(page < pages.length - 1) setPage(page + 1)
        }else {
            if(page > 0) setPage(page - 1)
        }

    }, [pages, page])

    useLayoutEffect(()=>{

        function f(){
            const {width} = ref.current.getBoundingClientRect();
            // console.log(width)
            let c = parseInt(width/100);
            let i = 0;
            let size = 4;

            if(c % 2 === 1){
                i = (c-1)/2
            }
            else {
                i = c/2;
            }

            size = i * 3 + (c-i) * 4

            setPages(_.chunk(elements, size));
            setPage(0);
        }
        
        setTimeout(() => {
            f()
        }, 1000);
        window.addEventListener("resize", f);

        return ()=>window.removeEventListener("resize", f);

    }, [elements])

    useEffect(()=>{
        // console.log(pages.length)
        const lst = pages[page];
        
        setElmts([]);
        let i=0, j=0;
        let max = 4;

        while (i<lst.length) {
            let elm=[];
            let k = max;
            
            if (j%2 !== 0){
                k = max - 1;
            }
            
            for (let p = 0; p < k; p++) {
                if (i+p<lst.length){
                    elm.push(lst[i+p])
                }
                else {
                    break;
                }
                
            }
            i+=k;
            
            setElmts(v=>[...v, (
                <>
                    {elm.map((v, i)=>(
                        <div className="img" key={i} >
                            <motion.img src={v} alt="client" 
                                initial={{scale: 0, opacity: 0}}
                                animate={{scale: 1, opacity: 1}}
                             />
                        </div>
                    ))}
                </>
            )]);
            j+=1;
        }
        
    }, [page, pages])

    return (
        <motion.div className="clients frame" style={{flex: 1, display: "flex", flexDirection: "column"}}
            onViewportEnter={()=>started.current=true} 
            onViewportLeave={()=>started.current=false}
        >
            <div style={{display: "flex", margin: "1em 0"}}>
                <span onClick={()=>handlePage("previous")}> 
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5.5a.5.5 0 0 0 0-1H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5z"/>
                    </svg>
                </span>
                <h3 style={{flex: 1, margin:0}}>Ils nous ont fait confiance</h3>
                <span onClick={()=>handlePage("next")} style={{transform: "rotate(180deg)"}} >
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5.5a.5.5 0 0 0 0-1H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5z"/>
                    </svg>
                </span>
            </div>
            
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flex: 1}} ref={ref}>
                <div className="images" >
                    {elmts.map((v, i)=>(
                        <div style={{justifyContent: i%2 !== 0?"space-between": "center"}} className="col" key={i}>{v}</div>
                    ))}
                </div>
            </div>
        </motion.div>
    )
}


export const AutoScroll = ({children, className, started, direction="left", initial=100})=>{
    const ref = useRef();
    const x = useMotionValue(initial);

    useAnimationFrame(()=>{
        if(!started.current) return;
        const scrollWidth = ref.current.scrollWidth;
        const {width: viewport} = ref.current.getBoundingClientRect()
        let current = x.get();


        if(scrollWidth <= viewport) {
            x.set(0);
            started.current = false
            return 
        }
        
        if(direction==="left"){
            if (current < -scrollWidth || current > viewport) current = viewport;
            x.set(current-1)
        }
        else {
            if (current > viewport || current < -scrollWidth) current = -scrollWidth;
            x.set(current + 1)
        }
        
    })

    return (
        <motion.div drag="x" ref={ref} style={{x}} className={className}>
            {children}
        </motion.div>
    )
}

export default Client;