import { CREATE_USER, LOGOUT, UPDATE_ACCOUNT } from "../constants"
import { setSetting } from "./settings"
import { get } from "./request"
import { DefaultStorage } from "../storage"

export const createUser = user =>{
    return {
        type: CREATE_USER,
        payload: user
    }
}

export const updateAccount = ()=>{

    return dispatch =>{
        dispatch(get({
            url: "accounts/",
            auth: true,
            key: "account",
            success: data=> dispatch({
                type: UPDATE_ACCOUNT,
                payload: data
            })
        }))
    }
}

export const logout = ()=>{
    return async dispatch => {
        await DefaultStorage.removeItem("persist:root")

        // dispatch(clearOrmState());
        
        dispatch(setSetting("openLogout", false));
        
        dispatch({
            type: LOGOUT
        });

        // await Updates.reloadAsync();

        console.log("----- LOGOUT ------")

    }
}





