import { useNavigate } from "react-router-dom"
import { Button } from "./tools/formTools"
import {motion} from "framer-motion";
import { cardProps } from "./tools";
import { rgbaDefault } from "../styles/style";

const ServiceCard = ({name, title})=>{
    const navigate = useNavigate();

    return (
        <motion.div {...cardProps} style={{display: "flex"}}>
            <motion.div className="service-card" animate={{boxShadow: `0px 2px 3px 2px ${rgbaDefault(.6)}`}}>
                <h4>{title}</h4>

                <div className="triangles">
                    <div className="left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="10" viewBox="0 0 100 10" fill="none">
                            <path d="M0 0H100L0 10V0Z" fill="#000066"/>
                        </svg>
                    </div>
                    <div className="right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="10" viewBox="0 0 100 10" fill="none">
                            <path d="M0 0H100V10L0 0Z" fill="#000066"/>
                        </svg>
                    </div>
                </div>

                <div className="body">
                    <p>{DESC[name]}</p>
                    <Button onClick={()=>navigate(`/services?selected=${name}`)}>En Savoir +</Button>
                </div>
            </motion.div>
        </motion.div>
    )
}

const DESC = {
    advice: `
        Alignez votre organisation avec votre stratégie grâce à notre expertise en 
        management et en structuration.
    `,
    audits: `
        Répondez à vos besoins spécifiques avec notre 
        large gamme d'audits selon les normes internationales.
    `,
    risk:`
        Gérez efficacement les risques et garantissez la continuité de votre entreprise 
        grâce à notre approche basée sur le COSO 2017.
    `,
    formation:`
        Renforcez vos compétences dans des domaines clés grâce à nos formations 
        interactives dispensées par des experts certifiés.
    `,
    information: `
        Optimisez votre prise de décision et le pilotage continu de votre entreprise 
        avec nos solutions performantes en informatique.
    `
    
}

export default ServiceCard;