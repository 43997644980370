import { CLEAR_ERRORS, SET_ERRORS, SHOW_MESSAGE } from "../constants";


const _ = require("lodash")

const flat = (dict, result={}, prefixed="") =>{
    const flt = _.transform(dict, function(rst, value, key){
        if( _.isObject(value) && ! _.isArray(value)){
            flat(value, rst, key)
        }
        else{
            rst[`${prefixed && prefixed + "_"}${key}`] = value
        }
    }, result)
    
    return flt

}

const init ={
    errors: {},
    message:{
        type: "success",
    }
}

const alertReducer = (state=init, action)=>{
    switch(action.type){
        case SET_ERRORS:
            return {
                ...state,
                errors: flat(action.value)
            }
        
        case SHOW_MESSAGE:
            return {
                ...state,
                message:{
                    ...state.message,
                    ...action.payload
                }
            }
        case CLEAR_ERRORS:
            return init

        default:
            return state
    }
}

export default alertReducer