import { motion } from "framer-motion";
import Container from "../components/Container"
import { Button, Input, PhoneNumber } from "../components/tools/formTools";
import ContactSvg from "../components/DesignSvg/ContactSvg";
import { useCallback, useState } from "react";
import freader from "../components/tools/formReader";
import { useDispatch } from "react-redux";
import { post } from "../store/action/request";
import { MESSAGE } from "../store/apiUrls";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../store/action/notification";


const Contact = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [number, setNumber] = useState("");

    const submit = useCallback(e=>{ 
        e.preventDefault();

        let data = freader(
            e.target, 
            ["fullName", "email", "message", "object"],
            {"message": "msg"}
        )
        data["phone_number"] = number;
        // console.log(data)

        dispatch(post({
            url: MESSAGE,
            data,
            key: "message",
            success: ()=>{
                dispatch(addNotification({
                    title: "Succès !!!",
                    message: `Mr/Mme ${data.full_name} votre message à été envoyer avec succès.`
                }, 5000))
                navigate("/");
            }
        }))

    }, [dispatch, navigate, number])

    return (
        <div className="contact">
            <Container page="contact">

                <motion.form className="form" layoutId="request" onSubmit={submit} onClick={e=>e.stopPropagation()}>
                    <h3>Contactez-nous</h3>

                    <Input label="Nom et prénoms" name="fullName" required pk="full_name"/>
                    
                    <Input label="Address e-mail" required name="email" pk="email"/>
                    <PhoneNumber label="Votre numéro" pk="phone_number" name="phoneNumber" value={number} setValue={setNumber} />

                    <Input label="Objet" required name="object" pk="object" />
                    <Input label="Votre message" type="textarea" required name="message" pk="msg" />
                    <div className="btn-row" style={{display: "flex", justifyContent: "center", marginBottom: 5}}>
                        <Button style={{minWidth: 200}} pk="message">Envoyer</Button>
                    </div>
                </motion.form>

                <div className="illustration">
                    <ContactSvg/>
                </div>
                
            </Container>
        </div>
    )
}

export default Contact;