import { useEffect, useRef, useState } from "react"
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function importAll(r) {
    // let images = {};
    let images = [];
    r.keys().forEach((item, index) => {
    //   images[item.replace('./', '')] = r(item);
        images.push(r(item));
    });
    return images;
}

const Partenaire = ()=>{
    const [elmts, setElmts] = useState([]);
    const started = useRef(false);


    useEffect(()=>{
        const lst = importAll(require.context('../assets/partenaires', false, /\.(png|jpe?g|svg)$/))
        
        setElmts([]);

        setElmts(
            lst.map((v, i)=>(
                <div className="img" key={i} >
                    <img src={v} alt="partenaire" />
                </div>
            ))
        );
        
    }, [])

    return (
        <motion.div className="partenaires frame"
            onViewportEnter={()=>started.current=true} 
            onViewportLeave={()=>started.current=false}
        >
            <div className="bg"></div>
            
            <h3>Nos Partenaires</h3>

            <div className="images">
                {elmts.map((v, i)=>(
                    <div className="col" key={i}>{v}</div>
                ))}
            </div>
            {/* <AutoScroll className="images" started={started} initial={0} direction="right">
            </AutoScroll> */}
            
            <div className="become" style={{zIndex: 48}}>
                <Link to="/partenaire" className="btn">
                    Devenez partenaire
                </Link>
            </div>
        </motion.div>
    )
}

export default Partenaire;