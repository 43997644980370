import { motion } from "framer-motion"
// import TitleSvg from "../DesignSvg/TitleSvg"


export const Title = ({children, className=""}) =>{

    return (
        <div  className={`title ${className}`}>
            <div className="decor">
                {/* <TitleSvg/> */}
                <svg width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
            </div>
            <motion.h1 
            >
                <motion.div
                    initial={{x: -100, opacity: 0, y: -5}}
                    whileInView={{x: -30, opacity: 1, y: -5}}
                    // transition={{duration: 1}}
                >
                    {children}
                </motion.div>
            </motion.h1>

        </div>
    )
}

export const cardProps = {
    variants:{
        enter:{
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.7,
                // delayChildren: 0.3,
                // staggerChildren: 0.05
            }
        },
        free: {
            clipPath: "inset(10% 50% 90% 50% round 10px)",
            transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3
            }
        } 
    }

}

