import React, { useState, useCallback } from 'react';
import { motion } from 'framer-motion';

const Typing = ({content="", once=true, duration=50, delay=10}) => {
    const [text, setText] = useState('');
    // const controls = useAnimation();
    // const [opacity, setOPacity] = useState(0);
  
    const start = useCallback(() => {
        let index = 0;
        if(text) return;
        let interval;

        let timeout = setTimeout(()=>{
            interval = setInterval(() => {
                if (index <= content.length) {
                    setText(content.slice(0, index));
                    index++;
                    // setOPacity(1);
                } else {
                    clearInterval(interval);
                    // setOPacity(0);
                }
                // setOPacity()
            }, duration);

        }, delay)
    
        return () => {
            interval && clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [content, text, delay, duration]);
    
    const leave = useCallback(()=>{
        if(!once) setText("");
    }, [once])

    return (
        <>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
            {/* <span > */}
                <motion.span
                    onViewportEnter={start}
                    onViewportLeave={leave}
                >
                    {text}
                </motion.span>
                {/* <motion.span
                    animate={{ width: '1px', height: '1em', background: 'black', marginLeft: '0.2em',opacity }}
                    // animate={controls}
                /> */}

            {/* </span> */}
            {/* </div> */}
        </>
    );
};
    
export default Typing;