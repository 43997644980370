import Container from "../components/Container";
import { motion } from "framer-motion";
import { Button, CheckBtn } from "../components/tools/formTools";
import { Title } from "../components/tools";
import { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import RequestService from "../components/RequestService";

const Services = ()=>{
    const [selectedList, setSelectedList] = useState([]);
    const [search] = useSearchParams();
    const ref = useRef();
    const [open, setOpen] = useState(false);


    const handelSelected = useCallback((item)=>{
        setSelectedList(lst=>{
            if(lst.includes(item)){
                let a = [...lst]
                _.remove(a, i=>i===item)
                return a
            }
            return [...lst, item]
        })
    }, []) 

    useEffect(()=>{
        let service = search.get("selected");

        if(["advice", "audits", "risk", "formation", "information"].includes(service)){
            setSelectedList(lst=>!lst.includes(service) ? [...lst, service]: lst)

            let elm = document.querySelector("."+service)
            let container = document.querySelector(".container-canvas")
           
            elm && container.scrollTo({top: elm.offsetTop - 50, behavior: 'smooth',})
        
        }
    }, [search, handelSelected, ref])

    return (
        <div className="services">
            <Container 
                ref={ref}
                page="services"
                headerBtn={
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Button className="request" onClick={()=>setOpen(true)} layoutId="request">
                            <span>Faire une demande</span>
                            <div className="icon"></div>
                        </Button>
                    </div>
                }
            >
                <div className="body">
                    <Service selectedList={selectedList} handleSelected={handelSelected} name="advice" title="Conseil">
                        <Check label={<><strong>Réingéniering</strong> de votre entreprise (manuels de procédures, etc)</>}/>
                        <Check label={<>Bâtir la <strong>stratégie</strong> de votre entreprise</>}/>
                        <Check label={<>Mettre en place un système de <strong>pilotage de la performance</strong> </>}/>
                        <Check label={<>Accroître la performance de l’entreprise par la <strong>réduction des frais généraux et de maîtrise des coûts</strong> (LeanPerformance)</>}/>
                        <Check label={<>Soutenir  le <strong>développement</strong> de votre activité avec des financements structurés (levées de fonds, subvention) </>}/>
                        <Check label={<>Améliorer la performance des entités publiques par la <strong>Gestion Axée sur le Résultat</strong></>}/>
                    </Service>

                    <Service selectedList={selectedList} handleSelected={handelSelected} name="audits" title="Audits Thématiques">

                        <Check label={<>Audit <strong>financier</strong> et comptable (IAS/IFAC/INTONSAI)</>}/>
                        <Check label={<>Audit <strong>organisationnel</strong> (IIA)</>}/>
                        <Check label={<>Audit <strong>stratégique</strong> (SWOT)</>}/>
                        <Check label={<>Audit de la <strong>fonction</strong> Achats (IIA)</>}/>
                        <Check label={<>Audit des Stocks (IIA)</>}/>
                        <Check label={<>Audit des <strong>Ressources Humaines</strong> (ISA)</>}/>
                        <Check label={<>Audit des <strong>systèmes d’information</strong> (COBIT)</>}/>
                        <Check label={<>Audit sur la recherche de <strong>fraude</strong> (IIA)</>}/>
                        <Check label={<>Audit de tout ou partie d’un <strong>processus opérationnel</strong> (IIA)</>}/>
                    </Service>

                    <Service selectedList={selectedList} handleSelected={handelSelected} name="risk" title="Risk Management">
                        <Check label={<>Evaluer les <strong>risques opérationnels</strong> de votre entreprise</>}/>
                        <Check label={<>Mettre en place la <strong>cartographie des risques</strong> de vos activités clés</>}/>
                        <Check label={<>Mettre en place un <strong>système de conformité</strong> ainsi que des  procédures et des outils de LBT/FT</>}/>
                        <Check label={<>Bénéficier d’un <strong>IAO</strong> ou Outsourcing de la fonction d’audit interne:</>}>
                            <SubCheck>Mise en place de la fonction d’audit interne</SubCheck>
                            <SubCheck>Evaluation du dispositif de contrôle interne</SubCheck>
                            <SubCheck>Réalisation des missions de contrôle</SubCheck>
                            <SubCheck>Reporting des missions de contrôle (français/anglais/espagnol)</SubCheck>
                            <SubCheck>Suivi des recommandations et des plans d’action</SubCheck>
                            
                        </Check>

                    </Service>

                    <Service selectedList={selectedList} handleSelected={handelSelected} name="formation" title="Formation">
                        <Check label={<>Perfectis Consulting est partenaire agréé de IFC-LPI (Banque Mondiale) pour les programmes de formation destinés aux PME (<a rel="noreferrer" target="_blank" href="https://growlearnconnect.org">growlearnconnect.org</a>)</>}/>
                        <Check label={<>Privilégier les études de cas, les ateliers, les échanges d’expérience pour asseoir les fondements théoriques (<strong>Learn by doing</strong>)</>}/>
                        <Check label={<>Evaluer à chaud puis à froid la formation avec un PAP</>}/>
                        <Check label={<>Améliorer votre dispositif de contrôle interne avec le <strong>Passeport Perfectis™</strong>: 6 modules de formation sur l’audit interne</>}>
                            <SubCheck>Formateur Certifié IFC-LPI - 2010</SubCheck>
                            <SubCheck>Ingénieur Pédagogique IFC-LPI – 2016</SubCheck>
                            <SubCheck>Formateur de Formateurs IFC – 2017</SubCheck>
                            <SubCheck>Evaluateur de formateurs Certifié IFC-LPI – 2018</SubCheck>
                            <SubCheck>Formateur Certifié IFC-LPI - 2018</SubCheck>
                        </Check>

                    </Service>

                    <Service selectedList={selectedList} handleSelected={handelSelected} name="information" title="Information System">
                        <Check label={<>Réalisations</>}>
                            <SubCheck>
                                Développement d’une application de gestion de la production intégrant tout le processus de production d’impression depuis la commande 
                                du client jusqu’au choix des rotatives en passant par la gestion des stocks des intrants (encres, papiers, etc.) (Sté CEMPA SA)
                            </SubCheck>
                            <SubCheck>
                                Développement d’un outil de gestion budgétaire intégrant les lignes directrices du Lean Management ayant permis une réduction de 
                                près de 30% des achats de matières premières (Sté CIEL VERT SA)
                            </SubCheck>
                            
                        </Check>

                    </Service>
                    
                </div>
                <RequestService setSelectedList={setSelectedList} selectedList={selectedList} handleSelected={handelSelected} {...{open, setOpen}}/>
            </Container>
        </div>
    )
}

const Service = ({title, name, children, handleSelected, selectedList})=>{

    return (
        <div 
            className={`service ${name}`} id={name}

        >
            <Title>{title}</Title>
            <div className="service-body">
                <p>{TEXT[name]}</p>

                <motion.div className="service-container"
                    whileInView="in"
                    initial="out"
                >
                    <motion.div 
                        className="vertical-bar"
                        initial={{height: "0%"}}
                        whileInView={{height: "100%", transition:{duration: 1, delay: .5}}}
                    ></motion.div>
                    <motion.div 
                        className="children"
                        
                        // transition={{staggerChildren: .5,}}
                        variants={{
                            in: {opacity: 1, y: 0},
                            out: {opacity: 0, y: 50}
                        }}
                        transition={{duration: 1}}
                    >
                        {children}
                    </motion.div>
                </motion.div>
                <div style={{display: "flex", }}>
                    <CheckBtn checked={selectedList.includes(name)} toggle={()=>handleSelected(name)} >Sélectionner</CheckBtn>
                </div>
            </div>

        </div>
    )
}

const Check = ({children, label})=>{
    
    return (
        <div className="service-check">
            <div className="label">
                <motion.span
                    variants={{
                        in: {
                            opacity: 1
                        },
                        out:{
                            opacity: 0
                        }
                    }}
                >
                    √
                </motion.span>
                <label>{label}</label>
            </div>

            <div className="service-subcheck">
                {children}
            </div>
        </div>
    )
}

const SubCheck = ({children})=>{
    
    return (
        <div className="subcheck">
            <div className="dot">
                <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Frame 42">
                    <g id="Group 1">
                    {/* <circle id="Ellipse 1" cx="10" cy="12" r="9.5" fill="#D9D9D9" stroke="#000066"/> */}
                    <circle id="Ellipse 2" cx="10" cy="12" r="5.5" fill="#000066" stroke="#000066"/>
                    </g>
                    </g>
                </svg>
            </div>
            <label>{children}</label>
            
        </div>
    )
}



const TEXT = {
    advice: `
        Aligner votre organisation avec la stratégie définie. Il s'agit d'effectuer une analyse complète de votre structure, 
        en mettant l'accent sur les sujets de management et d'organisation. 
        Cela comprend un audit complet de votre fonctionnement managérial et structurel, afin d'identifier les forces en 
        présence et de structurer l'organisation pour en tirer le meilleur parti. Des outils développés en interne, 
        conformément à la norme ISO 9001, permettent d'effectuer une analyse 360° de votre organisation en moins de 5 jours.
    `,
    audits: `
        Offrir une large gamme d'angles d'analyse dans des domaines spécifiques en réponse aux besoins identifiés ou exprimés par votre entreprise. 
        Nous réalisons ces audits selon les différentes normes internationales en vigueur, tels que les audits financiers et comptables, organisationnels, 
        stratégiques, d'achats, de stocks, des ressources humaines, des systèmes d'information, de recherche de fraude, et des processus opérationnels.
    `,
    risk: `
        Assurer une gestion efficace des risques pour garantir la continuité de votre organisation. Notre approche du risk management repose sur le référentiel 
        de contrôle interne COSO 2017. Nous évaluons les risques opérationnels de votre entreprise, mettons en place la cartographie des risques de vos activités clés, 
        développons des systèmes de conformité et des outils de lutte contre le blanchiment des capitaux et le financement du terrorisme. Nous proposons également des 
        services d'externalisation de la fonction d'audit interne.
    `,
    formation: `
        Renforcer les compétences dans des domaines tels que la comptabilité, les ressources humaines, l'audit externe ou interne, la finance d'entreprise et la gestion de la performance. 
        Nous proposons des formations basées sur des études de cas et des méthodes pédagogiques interactives, avec des formateurs certifiés. 
        Nous sommes également partenaires agréés de l'IFC-LPI (Banque Mondiale) pour les programmes de formation destinés aux PME.
    `,
    information:(
        <>
            Construire des solutions performantes pour améliorer la prise de décision et le pilotage continu de votre entreprise. Nous proposons des solutions de Business Intelligence, 
            Knowledge Management, et EIS (Enterprise Information Systems) grâce à notre plateforme <span style={{color: "green"}}>enov.business-solutions™</span>, notamment avec CODIX 2.4™, une solution en finance inclusive 
            en mode SaaS. Nous avons réalisé des développements d'applications de gestion de la production et d'outils de gestion budgétaire.
        </>
    )

    
}

export default Services;