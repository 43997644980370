import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home";
import "../styles/index.scss";
import Services from "./Services";
import Contact from "./Contact";
import Partner from "./Partner";


const Pages = ()=>{

    return (
        <BrowserRouter>
			<Routes>
				<Route path="" element={<Home/>} />
				<Route path="/services" element={<Services/>}/>
				<Route path="/contact-us" element={<Contact/>}/>
				<Route path="/partenaire" element={<Partner/>} />
			</Routes>
		</BrowserRouter>
    )
}

export default Pages;