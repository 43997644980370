import { Link } from "react-router-dom";
import { animate, motion, stagger } from "framer-motion";
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";


const Menu = ({shadow, page})=>{
    const [hide, setHide] = useState(true);
    const [small, setSmall] = useState(false);
    const [opacity, setOpacity] = useState(0);

    useLayoutEffect(()=>{
        function f(){
            const sm = window.innerWidth < 550;
            setSmall(sm);
            setHide(sm);
        }
        f();

        window.addEventListener("resize", f);

    }, [])

    useLayoutEffect(()=>{
        
        const seq = hide?[
            [
                "nav", 
                // {width: 0}, 
                {y: 100, opacity: 0, scale: .7},
                {duration: .3, delay: stagger(.2, {from: "last"})}
            ],
            [document.querySelector("div.menu"), {x: "-100%"}, {duration: .2}],
            [
                document.querySelector("div.menu-box"), 
                {
                    // zIndex: -1
                    x: "-100%"
                }, 
                {duration: 0.1, at: "+1"}],

        ]:[
            [
                document.querySelector("div.menu"), 
                {x: "0%"}, 
                {duration: .3, ease: [0.08, 0.65, 0.53, 0.96]}
            ],
            [
                document.querySelector("div.menu-box"), {
                    x: "0"
                }
                , { duration: .01, at:"<"}
            ],
            [
                "nav", 
                // {width: "100%"}, 
                {y: 0, opacity: 1, scale: 1},
                {duration: .5, delay: stagger(.2), type: "spring", stifness: 500, at: "<"}
            ],
        ]

        let seqs = [
            [
                "path.top",
                { d: !hide ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
                { at: "<" }
            ],
            ["path.middle", { opacity: !hide ? 0 : 1 }, { at: "<" }],
            [
                "path.bottom",
                { d: !hide ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
                { at: "<" }
            ],
        ]

        seqs.push(...seq)

        const anim = animate(seqs);
        anim.then(()=>setOpacity(1))
        
        // anim.complete(()=>console.log("end"))
    }, [hide, small])

    
    return (
        <div 
            className={`menu-box ${small?"mobile": "desktop"}`} 
            style={{
                // position: small? "absolute": null,  
                zIndex: 1000, 
                display: "flex", 
                opacity: small? opacity: 1,
            }}
        >
            <div 
                className="menu-wrapper" 
                style={{
                    // overflow: "hidden", 
                    width: "auto",
                    // width: 0
                }} 
                // initial={{width: 0}} 
                // animate={{width: hide? 0: null}}
            >
                <motion.div className="menu"
                    initial={{boxShadow: "2px 0 1px 2px rgba(0, 0, 0, 0)"}}
                    animate={{boxShadow: `${small? 2: 0}px 2px 3px 2px rgba(0, 0, 0,  ${shadow && !hide? .4: 0})`}}
                >
                    <nav onClick={()=>small && setHide(true)}>
                        <NavLink to="/" active={page==="home"}>Accueil</NavLink>
                        <NavLink to="/services" >Services</NavLink>
                        <NavLink to="/?to=partenaires" active={page==="partenaires"}>Clients & Partenaires</NavLink>
                        <NavLink to="/contact-us">Contact</NavLink>
                    </nav>

                    
                </motion.div>
            </div>
            
            {small && (
                <MenuIcon setHide={setHide}/>
            )}
        </div>
    )
}

const MenuIcon = ({setHide})=>{

    return createPortal(
        <div onClick={()=>setHide(v=>!v)} className="menu-icon">
            <svg width="23" height="18" viewBox="0 0 23 18" fill="currentcolor">
                <Path
                    d="M 2 2.5 L 20 2.5"
                    className="top"
                    variants={{
                        closed: { d: "M 2 2.5 L 20 2.5" },
                        open: { d: "M 3 16.5 L 17 2.5" }
                    }}
                />
                <Path d="M 2 9.423 L 20 9.423" opacity="1" className="middle" />
                <Path
                    d="M 2 16.346 L 20 16.346"
                        className="bottom"
                        variants={{
                        closed: { d: "M 2 16.346 L 20 16.346" },
                        open: { d: "M 3 2.5 L 17 16.346" }
                    }}
                />
            </svg>
        </div>
        , document.body
    )
}

const NavLink = ({to, children, active=null}) =>{
    return (
        <div className="nav-link">
            {/* {window.href} */}
            <Link to={to}>{children}</Link>
            {((active===null && window.location.pathname.endsWith(to)) || active) && (
                <motion.div layoutId="nav-back" className="back"></motion.div>
            )}
        </div>
    )

}

const Path = (props) => (
	<path
		fill="transparent"
		strokeWidth="3"
		stroke="rgb(0, 0, 102)"
		strokeLinecap="round"
		{...props}
	/>
  );

export default Menu;
