import { AXIOS_ALERT, LOADING, LOADING_LOCATION } from "../constants"
import axios from "axios"
import { setErrors } from "./alert"
import { HOST } from "../apiUrls";
// import { refreshToken } from "./token"


const dispatch = action => {
    const {store} = require("..");
    store.dispatch(action)
}

const logout = ()=>{
    let  { logout: lg } = require("./user");

    return lg();
}

export const axiosAlert = ({message, type}) =>{

    return {
        type: AXIOS_ALERT,
        payload:{
            message,
            type
        }
        
    }
}

export const loadingLocation = (key, state)=>{
    let payload = {};
    payload[key] = state;

    return {
        type: LOADING_LOCATION,
        payload
    }
}

export const loading = (value)=>{

    return {
        type: LOADING,
        payload: value
    }
}

const instance = axios.create({
    baseURL: HOST,
    headers: {
        "Content-Type": 'application/json',
        
    }
})

 // add interceptor
 instance.interceptors.request.use(
     
     function (config) {
        dispatch(loading(true))
        return config;
    }, 
    function (error) {

        dispatch(loading(false))

        
        return Promise.reject(error);
    }
)

instance.interceptors.response.use(
    response => {
        dispatch(loading(false))

        return response
    }, 
    (error) =>{
        

        dispatch(loading(false))
        console.log(error.response)
        if (error.code === "ERR_BAD_REQUEST"){
            const data = error.response.data
            // console.log(error.response.responseHeaders)

            // if(error.response.status === 401) dispatch(logout())
            // else 
            dispatch(setErrors(data))
        }
        
        else if(error.code === "ERR_NETWORK"){

            dispatch(setErrors({detail: "network_error"}));
        }                  
        return Promise.reject(error)
    }
)

const config = ({auth=false, file=false, getState})=>{
    let headers = {}
    if (auth) {
        const access = getState().token.access
        headers = {
            'Authorization': `Token ${access}`
        }
    }
    if(file)
        headers = {...headers, "Content-Type": 'multipart/form-data'}
    
    return {headers}

}


export const get = ({url, success, error,  auth=false, key, logout401=true})=>{
      
    console.log(url)
    return (dispatch, getState) =>{

        dispatch(loadingLocation(key, true))
        instance.get(url, config({auth, getState})).then(res =>{
            dispatch(loadingLocation(key, false))
            success && success(res.data)
        })
        .catch(err=>{
            dispatch(loadingLocation(key, false))

            error && error(err)

            if(err.response?.status === 401 && logout401) dispatch(logout());
            
        })
    }
}

export const post = ({url, data, success, error,  auth=false, file=false, key, logout401=true})=>{
        
    console.log(url)

    return (dispatch, getState) =>{

        dispatch(loadingLocation(key, true))
        instance.post(url, data, config({auth, file, getState})).then(res =>{
            dispatch(loadingLocation(key, false))
            success && success(res.data)
        }).catch(err=>{
            dispatch(loadingLocation(key, false))

            error && error(err);
            if(err.response?.status === 401 && logout401) dispatch(logout());
        })

    }

}

export const patch = ({url, data, success, error,  auth=false, file=false, key, logout401=true}) =>{

    return (dispatch, getState) =>{
        dispatch(loadingLocation(key, true))
        instance.patch(url, data, config({auth, file, getState})).then(res =>{
            dispatch(loadingLocation(key, false))
            success && success(res.data)
        }).catch(err=>{
            dispatch(loadingLocation(key, false))
            error && error(err);
            if(err.response?.status === 401 && logout401) dispatch(logout())
        })

    }

}
