
import { useAnimationFrame, useMotionValue, motion, useSpring } from 'framer-motion';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react'
import Flag from 'react-world-flags'


const Africa = ()=>{
    const imgRef = useRef();
    const frame = useRef();


    return (
        <div className="africa" ref={frame}>
            {/* <Plane target={imgRef} frame={frame} />
            <Plane target={imgRef} frame={frame} />
            <Plane target={imgRef} frame={frame} />
            <Plane target={imgRef} frame={frame} /> */}
            <div className="img">
                <img ref={imgRef} alt="Africa" src={require("../assets/africa.png")} />
            </div>
        </div>
    )
}

const next = ([x0, y0, xt, yt])=>{
    const a = (y0 - yt)/ (x0 - xt);
    const b = yt - a*xt;

    if(x0 < xt){
        x0 = x0 + 1;

    }
    else {
        x0 = x0 - 1;
    }
    y0 = a * x0 + b;

    return [x0, y0];
}

const dtx = ([x, y, n, t]) => Math.sqrt(Math.pow(x-n, 2) + Math.pow(y-t, 2))

export const Plane = ({target, frame})=>{
    const mem = useRef({ccx: 150, ccy: 150, vx: 1, vy: 1})
    const x = useMotionValue(-100);
    const y = useMotionValue(-200);
    const [code, setCode] = useState("BJ");
    const ref = useRef();

    const spx = useSpring(x, {stiffness: 5});
    const spy = useSpring(y, {stiffness: 5});

    const reset = useCallback(()=>{
        const axe = _.sample([0, 1]);
        const {width, height} = frame.current?.getBoundingClientRect();

        if(axe === 0){
            mem.current.fx = _.random(-10, parseInt(width));
            mem.current.fy = _.sample([-10, parseInt(height)])
        }
        else{
            mem.current.fx = _.sample([-10, parseInt(width)]);
            mem.current.fy = _.random(-10, parseInt(height)) 
        }
        setCode(_.sample(CODES));
        x.set(mem.current.fx);
        y.set(mem.current.fy);
        

        // animate(ref.current, {opacity: 1, scale: 1})

        // console.log(mem.current.fx, mem.current.fy)

    }, [mem, frame, x, y])

    useEffect(()=>{
        reset();
        
    }, [reset])
    
    useAnimationFrame((time)=>{
        if(!target.current) return 
        let {x: tx, y: ty} = target.current?.getBoundingClientRect();
        [tx, ty] = [parseInt(tx) + mem.current.ccx, parseInt(ty) + mem.current.ccy];

        
        let [cx, cy] = [x.get(), y.get()]
        
        if(parseInt(time)% 2 !==0) return //console.log(target.current.getBoundingClientRect())


        // set directions
        // const dx = (cx < tx) ? 1: cx===tx? 0 : -1;
        // const dy = (cy < ty) ? 1: cy===ty? 0: -1;

        const [nx, ny] = next([cx, cy, tx, ty])

        x.set(nx);
        y.set(ny);

        // x.set(cx + dx * mem.current.vx);
        // y.set(cy + dy * mem.current.vy);

        // x.set("90%")
        // y.set("100vh")
        // console.log(cx, tx)

        // if(dtx([nx, ny, tx, ty]) < 200){
        //     animate(ref.current, {opacity: 0, scale: 0}, {duration: .1})
        // }

        if(dtx([nx, ny, tx, ty]) < 10){
            // console.log("end", cx, cy)
            reset();
        }

    })


    return (
        <motion.div style={{x: spx, y: spy}}  className="flag" ref={ref}>
            <Flag code={code} height={15} />
        </motion.div >
    )
}


const CODES = [
    "DZ",
    "AO",
    "BJ",
    "BW",
    "BF",
    "BI",
    "CM",
    "CV",
    "CF",
    "TD",
    "KM",
    "CG",
    "CD",
    "DJ",
    "EG",
    "GQ",
    "ER",
    "SZ",
    "ET",
    "GA",
    "GM",
    "GH",
    "GN",
    "GW",
    "KE",
    "LS",
    "LR",
    "LY",
    "MG",
    "MW",
    "ML",
    "MR",
    "MU",
    "MA",
    "MZ",
    "NA",
    "NE",
    "NG",
    "RW",
    "ST",
    "SN",
    "SC",
    "SL",
    "SO",
    "ZA",
    "SS",
    "SD",
    "TZ",
    "TG",
    "TN",
    "UG",
    "ZM",
    "ZW"
]
  

export default Africa;
