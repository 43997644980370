import { createPortal } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Button, CheckBtn, Input } from "./tools/formTools";
import { useCallback } from "react";
import freader from "./tools/formReader";
import { post } from "../store/action/request";
import { SERVICE } from "../store/apiUrls";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../store/action/notification";


const RequestService = ({selectedList=[], handleSelected, open, setOpen})=>{
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const submit = useCallback(e=>{
        e.preventDefault();
        let data = freader(
            e.target, 
            ["fullName", "email", "number", "company", "site"], 
            {number: "phone_number"}
        )
        data.services = selectedList;

        dispatch(post({
            url: SERVICE,
            data,
            error: console.log,
            key: "service",
            success: ()=>{
                setOpen(false)
                navigate("/");

                dispatch(addNotification({
                    title: "Nouvelle demande !!!",
                    message: `Mr/Mme ${data.full_name} 
                    votre demande de service à été avec succès.`
                }, 50000))
            }
            
        }))
    }, [selectedList, dispatch, navigate, setOpen])

    return createPortal((
        <>
            <AnimatePresence>
                {selectedList.length > 0 && !open &&(
                    <motion.div className="request-btn" layoutId="request"
                        initial={{ opacity: 0}}
                        animate={{ opacity: 1}}
                        transition={{stiffness: 200, type: "spring"}}
                        exit={{ opacity: 0}}
                    >
                        <Button className="request" onClick={()=>setOpen(true)} >
                            <span>Finaliser la demande</span>
                            <div className="icon"></div>
                        </Button>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {open && (
                    <div className="request-modal" onClick={()=>setOpen(false)}>
                        <motion.form onSubmit={submit} className="form" layoutId="request" onClick={e=>e.stopPropagation()}>
                            <h3>Demande de Service</h3>
                            <div className="close" onClick={()=>setOpen(false)}>&times;</div>
                            <div className="check-service">
                                {["advice", "audits", "risk", "formation", "information"].map((name, i)=>(
                                    <CheckBtn key={i} checked={selectedList.includes(name)} toggle={()=>handleSelected(name)} >{SERVICES[name]}</CheckBtn>
                                ))}
                            </div>
                            <Input label="Nom et prénoms" name="fullName" required pk="full_name" />
                            {/* <div className="row"> */}
                            <Input label="Address e-mail" type="email" name="email" required pk="email" />
                            <Input label="Numéro" name="number" required pk="phone_number"/>
                            {/* </div> */}
                            <div className="row">
                                <Input label="Nom de votre entreprise" name="company" pk="company"/>
                                <Input label="Site web" name="site" pk="site"/>
                            </div>
                            <div className="btn-row" style={{display: "flex", justifyContent: "center", marginBottom: 5}}>
                                <Button style={{minWidth: 200}} pk="service" disabled={selectedList.length === 0} >Envoyer</Button>
                            </div>
                        </motion.form>
                    </div>
                )}
            </AnimatePresence>
        </>
    ), document.body
    )
}

const SERVICES = {
    "advice": "Conseil",
    "audits": "Audits Thématiques",
    "risk": "Risk Management",
    "formation": "Formation",
    "information": "Information System",
}

export default RequestService