export const HOST = 
"http://127.0.0.1:8000/";
// "http://192.168.43.185:8000/";
// "http://192.168.1.115:8000/";
// "http://172.20.10.13:8000/";
// "http://192.168.1.103:8000/";

export const APP_HOST = 
"http://127.0.0.1:3000/";
// "http://192.168.43.185:3000/";
// "http://192.168.1.115:3000/";
// "http://172.20.10.13:3000/";
// "http://192.168.1.103:3000/";


export const SERVICE = "services/";
export const MESSAGE = "messages/";
export const NEWS_LETTER = "messages/newsletter/";
export const PARTNERS = "partners/";
