import { configureStore } from "@reduxjs/toolkit";
import { combineReducers} from "redux";

import requestReducer from "./reducers/requestReducer";
import tokenReducer from "./reducers/tokenReducer";
import userReducer from "./reducers/userReducer";
import alertReducer from "./reducers/alertReducer";
import notifyReducer from "./reducers/notificationReducer";

const RootReducer = combineReducers({
    token: tokenReducer,
    request: requestReducer,
    user: userReducer,
    alert: alertReducer,
    notify: notifyReducer,

})

export const store = configureStore({
    reducer: RootReducer,
})
