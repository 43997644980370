import { motion } from "framer-motion"


const Sector = ({name})=>{

    return (
        <motion.div className={`sector ${name}`} 
            whileHover="hover"
            whileTap={{scale: .9, transition:{stiffness: 300}}}
        >
            <motion.div className={`icon ${name}`}
                variants={{
                    hover:{
                        scale: 1.3,
                    }
                }}
            ></motion.div>
            <h4>{TITLES[name]}</h4>
        </motion.div>
    )
}



const TITLES = {
    managment: "Gestion de Projets",
    plane: "L’aéronautique",
    hotel: "L’hôtellerie",
    industry: "L’industrie",
    services: "Les services",
    bank: "La banque"
}

export const DESC = {
    managment: [
        `Évaluation de l'adéquation des systèmes de gestion financière de projet des Emprunteurs
        à travers les domaines de la budgétisation, des politiques et procédures, 
        des systèmes de contrôle interne, de la comptabilité, le reporting financier et l'audit`,
        `Audit financier de projets financés par les banques de développement`,
        `Audits organisationnel et financier et étude de la pérennité des activités du Programme`
    ],
    plane: [
        `Audit du système d’information (IT) et des processus Facturation et des Achats d’un aéroport`,
        `Audit de la fraude dans la gestion d’un aéroport (en français et espagnol)`,
        `Outsourcing de l’audit interne d’un groupe international aéroportuaire (cartographie des risques, 
        charte, code de déontologie, planification des missions)`
    ],
    hotel:[
        `Mission de conseil en réorganisation du 1er groupe de restauration au Sénégal `,
        `Missions de Mise à niveau de nombreux groupes hôteliers internationaux  
        (stratégique, financier, technique/énergétique, commercial)`,
    ],
    industry: [
        `Mise en place d’un système de Gestion dans la 1ère industrie africaine d’assemblages 
        de solutions Solaires (BP, levée de fonds, gestion de la performance, …)`,
        `Réorganisation financière, comptable et technique d’une imprimerie
        (Alignement sur les standards internationaux et application du Lean Management)`,
        `Mise en place d’un système de Contrôle budgétaire d’une société d’emballage et de Publicité`,
        `Conseil en stratégie, en gestion des RH et en réorganisation des systèmes d’information d’un concessionnaire automobile `
    ],
    bank:[
        `Etude de faisabilité et mise en place d’un contrôle de gestion basé sur la méthode ABC 
        (paramétrages, inducteurs de coût, indicateurs, tableaux de bord, procédures budgétaires …)`,
        `Renforcement du dispositif de contrôle interne et de gestion des risques d’une banque faitière (Alignement sur les exigences réglementaires de la CoBanc)`
    ],
    services: []
}

export default Sector;
