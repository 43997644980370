import { Link, useSearchParams } from "react-router-dom";
import Container from "../components/Container";
import { Title } from "../components/tools";
import { useEffect, useState } from "react";
import ServiceCard from "../components/ServiceCard";
// import Flag from 'react-world-flags'
import Sector, { DESC } from "../components/Sector";
import { AnimatePresence, motion} from "framer-motion";
import Partenaire from "../components/Partenaires";
import Client from "../components/Clients";

// const d = "right";
const Home = ()=>{
    const [search] = useSearchParams();
    const [open, setOpen] = useState();
   

    useEffect(()=>{
        let to = search.get("to");
        if(!to) to = "home";

        let elm = document.querySelector("."+to)
        let container = document.querySelector(".container-canvas")
           
        elm && container.scrollTo({top: elm.offsetTop - 50, behavior: 'smooth',})
        // console.log(container.getBoundingClientRect())
        

    }, [search])

    return (
        <div className="home">
            <Container page={search.get("to") === "partenaires"? "partenaires": "home"}>
                <div className="body">
                    <div className="skills">
                        <Title>Nos Compétences Métiers</Title>
                        <p>Grâce à notre expertise et à notre engagement envers l'excellence, nous offrons une gamme de services qui répondent aux besoins spécifiques de votre entreprise.</p>

                        <motion.div 
                            className="service-card-wrapper"
                            whileInView="enter"
                            viewport={{once: true}}
                            animate="free"
                            // initial="free"
                            transition={{staggerChildren: .07, delayChildren: .01}}
                        >
                            <ServiceCard name="advice" title={<><strong className="carfis-letter">C</strong>onseil</>} />
                            <ServiceCard name="audits" title={<><strong className="carfis-letter">A</strong>udits thématiques</>} />
                            <ServiceCard name="risk" title={<><strong className="carfis-letter">R</strong>isk management</>} />
                            <ServiceCard name="formation" title={<><strong className="carfis-letter">F</strong>ormation</>} />
                            <ServiceCard name="information" title={<><strong className="carfis-letter">I</strong>nformation <strong className="carfis-letter">S</strong>ystems</>} />
                        </motion.div>

                        <p>Perfectis Consulting a acquis une solide expérience dans différents secteurs d'activité à travers plusieurs pays. Nos réalisations ont couvert des domaines clés tels que:</p>
                        <motion.div whileInView="in" className="sectors" initial="free" transition={{staggerChildren: .5, delayChildren: .01}} viewport={{once: true}}>
                            {["managment", "plane", "hotel", "industry", "services", "bank"].map(name=>(
                                <motion.div  
                                    key={name}
                                    variants={{
                                        in:{
                                            scale: 1,
                                            opacity: 1,
                                            transition:{
                                                type: "spring",
                                                // bounce: 40,
                                                stiffness: 200
                                            }
                                        },
                                        free:{scale: .7, opacity: 0}
                                    }}
                                    onClick={()=>setOpen(name)}
                                    layoutId={name}
                                >
                                    <Sector name={name} key={name} />
                                </motion.div>
                            ))}

                            <AnimatePresence>
                                {open &&(
                                    <div className="opened-wrapper" onClick={()=>setOpen(false)}>
                                        <motion.div className="sector-opened" layoutId={open} >
                                            <Sector name={open}/>
                                            <motion.ul>
                                                {DESC[open].map((v, i)=><li key={i}>{v}</li>)}
                                            </motion.ul>
                                        </motion.div>
                                    </div>
                                )}
                            </AnimatePresence>
                        </motion.div>
                        
                        <div>
                            {/* <p>
                                Nous avons eu le privilège de travailler avec succès dans certains pays.
                                des pays tels que:
                                <br/>
                                Ces réalisations témoignent de notre expertise reconnue et 
                                de notre capacité à relever des défis complexes dans des environnements variés.
                            </p> */}

                            {/* <motion.div ref={cRef} className="countries-wrapper"
                                onViewportEnter={()=>started.current=true} 
                                onViewportLeave={()=>started.current=false}
                            >
                                <motion.div 
                                    transition={{x:{stiffness: 500, duration: 3}}} 
                                    style={{x: flagX}}  drag="x" className="countries"
                                >
                                    <div className="item">
                                        <Flag code="BJ" height={50} />
                                        <i>Bénin</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="BF" height={50} />
                                        <i>Burkina Faso</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="CM" height={50} />
                                        <i>Cameroun</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="FR" height={50} />
                                        <i>France</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="GH" height={50} />
                                        <i>Ghana</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="GQ" height={50} />
                                        <i>Guinée équatoriale</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="GN" height={50} />
                                        <i>Guinée</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="GW" height={50} />
                                        <i>Guinée-Bissau</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="NE" height={50} />
                                        <i>Niger</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="CF" height={50} />
                                        <i>Rep centrafricaine</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="SN" height={50} />
                                        <i>Sénégal</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="MR" height={50} />
                                        <i>Mauritanie</i>
                                    </div>
                                    <div className="item">
                                        <Flag code="ZA" height={50} />
                                        <i>Afrique du Sud</i>
                                    </div>
                                </motion.div>
                            </motion.div> */}
                            {/* <p>
                                Ces réalisations témoignent de notre expertise reconnue et 
                                de notre capacité à relever des défis complexes dans des environnements variés.
                            </p> */}
                        </div>
                    </div>

                    <motion.div 
                        className="partenaires-clients"
                        onViewportLeave={()=>search.delete("to")}
                    >
                        <p>
                            Nous avons eu le privilège de collaborer avec des partenaires de confiance afin de satisfaire un grand nombre de clients.
                        </p>
                        <div className="cards">
                            <Client/>
                            <Partenaire/>
                        </div>
                    </motion.div>

                    <div className="conclusion">
                        <motion.div 
                            className="container"
                            viewport={{once: true}}
                            initial={{y: 30, scale: .2, rotateZ: 50}}
                            whileInView={{
                                y: 0,
                                scale: 1,
                                rotateZ: 0
                            }}
                        >
                            <p>
                                Chez nous, les valeurs sont au cœur de tout ce que nous faisons. L'intégrité, la transparence et l'éthique guident chacune de nos actions. 
                                Nous mettons un point d'honneur à établir des relations de confiance durables avec nos clients et partenaires, en offrant des solutions personnalisées, 
                                fiables et de haute qualité. Notre engagement envers l'excellence, l'innovation et la satisfaction client est inébranlable. En choisissant notre entreprise, 
                                vous pouvez être assuré(e) que vous bénéficiez de notre expertise, de notre engagement indéfectible envers votre succès et d'une approche centrée sur vos besoins. 
                                Faites-nous confiance pour atteindre vos objectifs professionnels en toute sérénité.
                            </p>
                            <Link to="/services">Commencer</Link>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                                <strong>Cultures africaines, ambitions internationales</strong>
                                <div className="ul">
                                    <div>ETHIQUE</div>
                                    <div>COMPETENCE</div>
                                    <div>INDEPENDANCE</div>
                                    <div>INTEGRITE</div>
                                    <div>DISCRETION</div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Home;