

//Axios
export const LOADING = "LOADING";
export const SET_BASE_URL = "SET_BASE_URL";
export const AXIOS_ALERT = "AXIOS_ALERT";
export const LOADING_LOCATION = "LOADING_LOCATION";

//alert
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SHOW_MESSAGE = "SHOW_MESSAGE";


//Token
export const SET_TOKEN = "SET_TOKEN";

//User 
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const LOGOUT = "LOGOUT";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const SET_ACCOUNT = "SET_ACCOUNT"


//settings
export const SET_SCHEME = "SET_SCHEME";
export const SET_SETTINGS = "SET_SETTINGS";


//SOCKET 
export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const DISCONNECT_SOCKET = "DISCONNECT_SOCKET";
export const SEND_SOCKET = "SEND_SOCKET";


// NOTIFICATION 
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";