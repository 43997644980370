import { AnimatePresence, motion } from "framer-motion";
import { rgbaDefault } from "../../styles/style";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../store/action/alert";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


export const Button = ({onClick, children, className, animate, pk, ...props})=>{
    const dispatch = useDispatch();
    const {isLoading} = useSelector(state=>state.request);

    const clicked = useCallback(()=>{
        dispatch(clearErrors());
        onClick?.();

    }, [onClick, dispatch])

    

    return (
        <div className="btn-wrapper" 
            style={{
                overflow: "hidden", display: "flex", justifyContent: "center", 
                padding: 3, alignItems: "center", 
                // minWidth: btn.current?.getClientRef().width
            }}
        >
            {pk && isLoading[pk]? (
                <motion.div className="loading" layoutId={pk} ></motion.div>
            ):( null
            )}
            <motion.button
                {...props}
                onClick={clicked}
                whileTap={{scale: .97}}
                whileHover={{
                    scale: 1.01, 
                    borderColor: rgbaDefault(1), 
                    boxShadow: `0px 0px 2px 1px ${rgbaDefault(.5)}`
                }}
                animate={animate}
                className={`btn ${className}`}
                layoutId={pk}

            >
                {children}               
            </motion.button>
        </div>
    )
}


export const CheckBtn = ({children, className, checked, toggle, ...props})=>{

    return (
        <Button onClick={toggle} className={`checkable-btn ${className}`} {...props} type="button" >
            <div style={{display: "flex", alignItems: "center"}}>
                <motion.svg width="1.2em" height="1.2em" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Frame 42">
                    <g id="Group 1">
                        
                    <motion.circle id="Ellipse 1" cx="10" cy="12" fill="#D9D9D9" stroke="#000066"
                        initial={{r: 5.5}}
                        animate={{r: checked? 9.5: 5.5}}
                    />

                    <motion.circle 
                        initial={{r: 0}} animate={{r: checked?5.5: 0}} 
                        transition={{delay: .2}}
                        id="Ellipse 2" cx="10" cy="12" fill="#000066" stroke="#000066"
                    />
                
                    </g>
                    </g>
                </motion.svg>
                <div style={{marginLeft: 5}}>{children}</div>
                
            </div>
        </Button>
    )
}

const InputWrapper = ({label, pk, children, open, setOpen}) =>{
    const errors = useSelector(state=>state.alert.errors);

    return (
        <motion.div className="input" layout
        
        >
            <AnimatePresence>
                {open &&(
                    <motion.div 
                        className="legend" 
                        initial={{opacity: 0, y: 10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 10}}
                    >
                        {label}
                    </motion.div>
                )}
            </AnimatePresence>

            <motion.div 
                className="input-container" onClick={()=>!open&&setOpen(true)}
                animate={{border: `2px solid ${rgbaDefault(.5)}`, boxShadow: `0 0 2px 1px ${rgbaDefault(.4)}`}}

                transition={{border:{delay: 1}}}
            >                
                {children}

            </motion.div>

            {errors[pk] && (
                <ul>
                    {errors[pk].map((txt, i)=>(
                        <motion.li key={i}>
                            {txt}
                        </motion.li>
                    ))}
                </ul>
            )}
        </motion.div>
    )
}

export const Input = ({label, type, pk, placeholder, ...props}) =>{
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState("");

    return (
        <InputWrapper {...{label, pk, open, setOpen}}>
            {type !== "textarea"? (
                <motion.input 
                    placeholder={open?placeholder:label} 
                    type={type} autoFocus={open} 
                    animate={{opacity: 1, transition:{duration: .3}}}  
                    value={value} onChange={e=>setValue(e.target.value)} 
                    onBlur={()=>setOpen(false)}
                    {...props}
                />
            ): (
                <motion.textarea 
                    placeholder={open?"":label} 
                    autoFocus={open} 
                    animate={{opacity: 1, transition:{duration: .3}}}  
                    value={value} onChange={e=>setValue(e.target.value)} 
                    onBlur={()=>setOpen(false)}
                    {...props}
                />
            )}
        </InputWrapper>
    )
}


export const PhoneNumber = ({label, pk, name, value, setValue})=>{
    const [val, setVal] = useState("");
    const [open, setOpen] = useState(false);
    
    return (
        <InputWrapper {...{label, pk, open, setOpen}}>
            <PhoneInput
                value={value || val}
                onChange={setValue || setVal}
                placeholder="Numéro de téléphone"
                onBlur={()=>setOpen(false)}
                name={name}
            />
        </InputWrapper>
    )
}
