// import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/Container";
// import { useEffect } from "react";
import { Button, Input, PhoneNumber } from "../components/tools/formTools";
import PartnerSvg from "../components/DesignSvg/PartnerSvg";
import { useCallback, useState } from "react";
import freader from "../components/tools/formReader";
import { useDispatch } from "react-redux";
import { post } from "../store/action/request";
import { PARTNERS } from "../store/apiUrls";
import { addNotification } from "../store/action/notification";
import { useNavigate } from "react-router-dom";



const Partner = ()=>{
    const [number, setNumber] = useState("");
    const dispatch = useDispatch();
    
    // const {type} = useParams();
    const navigate = useNavigate();

    // useEffect(()=>{
    //     if(!["commercial", "financier", "technologique"].includes(type)){
    //         navigate("/?to=partenaires")
    //     }
    // }, [type, navigate])

    const submit = useCallback(e=>{
        e.preventDefault();
        let data = freader(
            e.target, 
            ["fullName", "email", "motivation", "company"],
        )
        data["phone_number"] = number;

        dispatch(post({
            url: PARTNERS,
            data,
            key: "partners",
            success: ()=>{
                dispatch(addNotification({
                    title: "Succès !!!",
                    message: `Mr/Mme ${data.full_name} votre demande à été envoyer avec succès.`
                }, 5000))
                navigate("/");
            }
        }))
        
    }, [number, dispatch, navigate])

    return (
        <div className="partner">
            <Container page="partner" >
                <form className="form" onSubmit={submit}>
                    <h3>Devenir Partenaire</h3>

                    <Input label="Nom et prénoms" name="fullName" pk="full_name" required/>
                    <div className="row">
                        <Input label="Address e-mail" name="email" pk="email" required />
                        <PhoneNumber label="Votre numéro" pk="phone_number" name="phoneNumber" value={number} setValue={setNumber} />
                    </div>
                    <div className="row">
                        <Input label="Nom de votre entreprise" name="company" pk="company"/>
                    </div>
                    
                    <Input label="Motivation" placeholder="Votre proposition" type="textarea" name="motivation" pk="motivation" required/>

                    <div className="btn-row" style={{display: "flex", justifyContent: "center", marginBottom: 5}}>
                        <Button style={{minWidth: 200}} pk="partners">Envoyer</Button>
                    </div>
                </form>
                <div className="illustration">
                    <PartnerSvg/>
                </div>
            </Container>
        </div>
    )
}



// const fields = (type)=>{

//     switch(type){
//         case "commercial":
//             return (
//                 <>
//                     <div className="row">
//                         <Input label="Nom de votre entreprise"/>
//                         <Input label="Pays/Zone géographique d'opération"/>
//                     </div>
//                     <Input label="Description de votre entreprise et de vos produits/services" type="textarea"/>
//                     <Input label="Motivation pour devenir notre partenaire commercial" type="textarea"/>
//                     <Input label="Autres détails pertinents" type="textarea"/>
//                 </>
//             )
//         case "technologique":
//             return (
//                 <>
//                    <div className="row">
//                         <Input label="Nom de votre entreprise technologique"/>
//                         <Input label="Domaine technologique"/>
//                     </div> 
//                     <Input label="Description de vos produits/services technologiques" type="textarea"/>
//                     <Input label="Pays/Zone géographique de votre activité"/>
//                     <Input label="Motivation pour devenir notre partenaire technologique" type="textarea"/>
//                     <Input label="Autres détails pertinents" type="textarea"/>
                    
//                 </>
//             )
        
//         case "financier":
//             return (
//                 <>
//                     <div className="row">
//                         <Input label="Nom de votre entreprise/institution"/>
//                         <Input label="Domaine d'investissement"/>
//                     </div> 
//                     <Input label="Pays/Zone géographique d'investissement préférée"/>
//                     <Input label="Montant d'investissement potentiel"/>
//                     <Input label="Description de vos domaines d'intérêt et critères d'investissement" type="textarea"/>
//                     <Input label="Motivation pour devenir notre partenaire financier" type="textarea"/>
//                     <Input label="Autres détails pertinents" type="textarea"/>
                    
//                 </>
//             )
//         default:
//             return 
//     }
// }

export default Partner;
