

export const  DfaultStorage = localStorage;

export class DefaultStorage {

    static async getItem(item){
        return localStorage.getItem(item);
    }

    static setItem(key, value){
        localStorage.setItem(key, value);
    }

    static removeItem(key){
        localStorage.removeItem(key);
    }
}