import { LOGOUT, SET_TOKEN } from "../constants";
import { DefaultStorage } from "../storage";


const tokenReducer = (state={}, action)=>{

    switch (action.type) {
        case SET_TOKEN :{
            return {
                ...state,
                ...action.payload,
            
            }
        }
        case LOGOUT:
            DefaultStorage.removeItem("TOKEN");
            return {};
            
        default:
            return state
    }
}

export default tokenReducer;